@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.wave-animation {
  position: absolute;
  height: 200px;
  width: 380px;
  top: 300px;
  right: 20px;
  bottom: 140px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.15) 100%);
  transform: translateX(100%);
  animation: wave 4.5s infinite linear;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
}

.pulse {
  position: relative;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  animation: pulse 2s infinite;
}